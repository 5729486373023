
.st0 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #ffe000;
}

#wanadev-loader-container {
    --tw-bg-opacity: 1;
    background-color: rgb(209 213 219 / var(--tw-bg-opacity));
    height: 100vh;
    width: 100vw;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1000;

    .loader-container {
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .loader-container svg {
        height: 200px;
    }
}

.fade-leave-active {
    transition: opacity 0.5s ease-in-out;
}

.fade-leave-to {
    opacity: 0;
}
