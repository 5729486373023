:root {
    --main-color: #ddd;

    --color-white: #ffffff;
    --color-charcoal-grey: #2f2b31;
    --color-grey: #707070;
    --color-white-two: #d8d8d8;
    --color-cool-blue: #5b9ecd;
    --color-dark-sky-blue: #4a90e2;
    --color-dark: #222036;
    --color-dark-two: #191923;
    --color-cornflower-blue: #5498d7;

    --left-menu-size: 118px;
    --right-menu-width: 216px;
    --material-menu-height: 242px;

    --main-menu-icon-gap: 7px;
}
