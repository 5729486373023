
.option-selector {
    margin-right: 1rem;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    .picker {
        display: flex
    }
    .picker {
        align-items: center
    }
    .picker {
        justify-content: center
    }
    .picker {
        border-radius: 0.125rem
    }
    .picker {
        border-width: 2px
    }
    .picker {
        --tw-border-opacity: 1;
        border-color: rgb(209 213 219 / var(--tw-border-opacity))
    }
    .picker {
        font-weight: 600
    }
    .picker {
        --tw-text-opacity: 1;
        color: rgb(209 213 219 / var(--tw-text-opacity))
    }

    &.active {
        font-weight: 600
    }

    &.active {
        --tw-text-opacity: 1;
        color: rgb(0 0 0 / var(--tw-text-opacity))
    }

    &.active {
        .picker {
            --tw-border-opacity: 1;
            border-color: rgb(0 0 0 / var(--tw-border-opacity))
        }
        .picker {
            --tw-text-opacity: 1;
            color: rgb(0 0 0 / var(--tw-text-opacity))
        }
    }
}
