
.tab-button {
    cursor: pointer;
    border-top-width: 2px;
    border-color: transparent;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.25rem;
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity))
}
@media (min-width: 1280px) {
    .tab-button {
        padding-left: 1rem;
        padding-right: 1rem
    }
}
.tab-button {
    &.active {
        --tw-border-opacity: 1;
        border-color: rgb(254 223 51 / var(--tw-border-opacity))
    }
    &.active {
        --tw-bg-opacity: 1;
        background-color: rgb(255 255 255 / var(--tw-bg-opacity))
    }
    &.active {
        font-weight: 700
    }
}
