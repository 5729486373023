@charset "UTF-8";
@font-face {
    font-family: "legallais-icons";
    src:
        url("../../assets/fonts/icons/legallais-icons.eot?#iefix") format("eot"),
        url("../../assets/fonts/icons/legallais-icons.woff") format("woff"),
        url("../../assets/fonts/icons/legallais-icons.ttf") format("truetype");
    font-display: swap;
}

/* line 11, sources/scss/base/_icons.scss */
.icon-360:before,
.icon-add-list:before,
.icon-adress:before,
.icon-adresse:before,
.icon-archive-border:before,
.icon-archive:before,
.icon-arrow-down:before,
.icon-arrow-down5:before,
.icon-arrow-down6:before,
.icon-arrow-down7:before,
.icon-arrow-left5:before,
.icon-arrow-left6:before,
.icon-arrow-right1:before,
.icon-arrow-right2:before,
.icon-arrow-right5:before,
.icon-arrow-right6:before,
.icon-arrow-up:before,
.icon-arrow-up4:before,
.icon-arrow2:before,
.icon-barcode:before,
.icon-basket-plain:before,
.icon-basket-v2:before,
.icon-basket:before,
.icon-bin:before,
.icon-book2:before,
.icon-box:before,
.icon-bulb:before,
.icon-burger:before,
.icon-calculator:before,
.icon-calendar:before,
.icon-camera:before,
.icon-cart:before,
.icon-catalog-v2:before,
.icon-catalog-wm:before,
.icon-catalog:before,
.icon-checkmark:before,
.icon-claim-open:before,
.icon-claim-wait:before,
.icon-claims:before,
.icon-clock:before,
.icon-cog:before,
.icon-comment:before,
.icon-computer:before,
.icon-contact:before,
.icon-convert-kit:before,
.icon-creditcard:before,
.icon-cross-thin:before,
.icon-cross:before,
.icon-cross3:before,
.icon-devis-plain:before,
.icon-document:before,
.icon-dot:before,
.icon-download:before,
.icon-download2:before,
.icon-drag-vertical:before,
.icon-duplicate:before,
.icon-e-catalog:before,
.icon-edit:before,
.icon-estimate:before,
.icon-exit-fullscreen:before,
.icon-export:before,
.icon-export2:before,
.icon-extend:before,
.icon-eye:before,
.icon-facebook:before,
.icon-favorite-border:before,
.icon-favorite-plain-2:before,
.icon-favorite-plain:before,
.icon-filter:before,
.icon-flag:before,
.icon-flow-tree:before,
.icon-fullscreen:before,
.icon-grid:before,
.icon-hamburger:before,
.icon-handicap:before,
.icon-heart:before,
.icon-heart2:before,
.icon-history:before,
.icon-home:before,
.icon-illico-02:before,
.icon-illico:before,
.icon-in-progress:before,
.icon-info-circle:before,
.icon-info:before,
.icon-infohelp:before,
.icon-instagram:before,
.icon-key:before,
.icon-kitting:before,
.icon-layout:before,
.icon-leaf:before,
.icon-left-open-big:before,
.icon-legallais:before,
.icon-link:before,
.icon-linkedin:before,
.icon-list-add-love:before,
.icon-list-add:before,
.icon-list:before,
.icon-list2:before,
.icon-location:before,
.icon-mail-v2:before,
.icon-mail:before,
.icon-map:before,
.icon-menu2:before,
.icon-minus:before,
.icon-new:before,
.icon-note-list:before,
.icon-note-text:before,
.icon-note:before,
.icon-novelty:before,
.icon-order-history:before,
.icon-organigram-circle:before,
.icon-organigram-cross:before,
.icon-organigram-plus:before,
.icon-paperclip:before,
.icon-pdf:before,
.icon-pdv:before,
.icon-pencil:before,
.icon-percent:before,
.icon-phone-fill:before,
.icon-phone:before,
.icon-piano:before,
.icon-pin-border:before,
.icon-pin:before,
.icon-plus:before,
.icon-plus2:before,
.icon-pos:before,
.icon-power:before,
.icon-price:before,
.icon-print-no-price:before,
.icon-print-price:before,
.icon-print-v2:before,
.icon-print:before,
.icon-product-list:before,
.icon-profile:before,
.icon-publish:before,
.icon-puzzle:before,
.icon-quotes:before,
.icon-reduce:before,
.icon-relay:before,
.icon-right-open-big:before,
.icon-saved-cart:before,
.icon-scan:before,
.icon-screen:before,
.icon-search:before,
.icon-share:before,
.icon-shop:before,
.icon-shopping-list-plain:before,
.icon-shopping-list-v2:before,
.icon-shopping-list:before,
.icon-shuffle:before,
.icon-similar:before,
.icon-star-v2:before,
.icon-star:before,
.icon-switch:before,
.icon-table:before,
.icon-tag:before,
.icon-text:before,
.icon-timer:before,
.icon-tools:before,
.icon-trash-border:before,
.icon-trash:before,
.icon-tree:before,
.icon-trophy:before,
.icon-trophy2:before,
.icon-twitter:before,
.icon-upload:before,
.icon-user-add:before,
.icon-user:before,
.icon-users:before,
.icon-vcard:before,
.icon-video-player:before,
.icon-video:before,
.icon-warning:before,
.icon-wplusm:before,
.icon-youtube:before {
    font-family: "legallais-icons";
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-decoration: none;
    text-transform: none;
    speak: none;
    display: inline-block;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    line-height: 1em;
    margin-left: 0.2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* line 578, sources/scss/base/_icons.scss */
.icon-360:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-add-list:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-adress:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-adresse:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-archive-border:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-archive:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-arrow-down:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-arrow-down5:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-arrow-down6:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-arrow-down7:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-arrow-left5:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-arrow-left6:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-arrow-right1:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-arrow-right2:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-arrow-right5:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-arrow-right6:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-arrow-up:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-arrow-up4:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-arrow2:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-barcode:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-basket-plain:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-basket-v2:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-basket:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-bin:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-book2:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-box:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-bulb:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-burger:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-calculator:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-calendar:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-camera:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-cart:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-catalog-v2:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-catalog-wm:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-catalog:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-checkmark:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-claim-open:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-claim-wait:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-claims:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-clock:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-cog:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-comment:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-computer:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-contact:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-convert-kit:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-creditcard:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-cross-thin:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-cross:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-cross3:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-devis-plain:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-document:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-dot:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-download:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-download2:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-drag-vertical:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-duplicate:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-e-catalog:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-edit:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-estimate:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-exit-fullscreen:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-export:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-export2:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-extend:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-eye:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-facebook:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-favorite-border:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-favorite-plain-2:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-favorite-plain:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-filter:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-flag:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-flow-tree:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-fullscreen:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-grid:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-hamburger:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-handicap:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-heart:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-heart2:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-history:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-home:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-illico-02:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-illico:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-in-progress:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-info-circle:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-info:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-infohelp:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-instagram:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-key:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-kitting:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-layout:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-leaf:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-left-open-big:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-legallais:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-link:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-linkedin:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-list-add-love:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-list-add:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-list:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-list2:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-location:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-mail-v2:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-mail:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-map:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-menu2:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-minus:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-new:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-note-list:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-note-text:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-note:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-novelty:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-order-history:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-organigram-circle:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-organigram-cross:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-organigram-plus:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-paperclip:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-pdf:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-pdv:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-pencil:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-percent:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-phone-fill:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-phone:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-piano:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-pin-border:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-pin:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-plus:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-plus2:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-pos:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-power:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-price:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-print-no-price:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-print-price:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-print-v2:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-print:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-product-list:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-profile:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-publish:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-puzzle:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-quotes:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-reduce:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-relay:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-right-open-big:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-saved-cart:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-scan:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-screen:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-search:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-share:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-shop:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-shopping-list-plain:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-shopping-list-v2:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-shopping-list:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-shuffle:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-similar:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-star-v2:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-star:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-switch:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-table:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-tag:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-text:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-timer:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-tools:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-trash-border:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-trash:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-tree:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-trophy:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-trophy2:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-twitter:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-upload:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-user-add:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-user:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-users:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-vcard:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-video-player:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-video:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-warning:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-wplusm:before {
    content: "";
}

/* line 578, sources/scss/base/_icons.scss */
.icon-youtube:before {
    content: "";
}
