
.location {
    margin-bottom: 1rem;
    border-radius: 0.25rem;
    border-width: 2px;
    border-color: transparent;
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity));
    .content {
        display: none
    }
    &.toggle {
        .content {
            display: block
        }
    }
}
