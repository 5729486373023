@font-face {
    font-family: "Lato";
    font-style: italic;
    font-weight: 100;
    font-display: auto;
    src: url("../../assets/fonts/Lato/Lato-ThinItalic.ttf");
}

@font-face {
    font-family: "Lato";
    font-style: italic;
    font-weight: 300;
    font-display: auto;
    src: url("../../assets/fonts/Lato/Lato-LightItalic.ttf");
}

@font-face {
    font-family: "Lato";
    font-style: italic;
    font-weight: 400;
    font-display: auto;
    src: url("../../assets/fonts/Lato/Lato-Italic.ttf");
}

@font-face {
    font-family: "Lato";
    font-style: italic;
    font-weight: 700;
    font-display: auto;
    src: url("../../assets/fonts/Lato/Lato-BoldItalic.ttf");
}

@font-face {
    font-family: "Lato";
    font-style: italic;
    font-weight: 900;
    font-display: auto;
    src: url("../../assets/fonts/Lato/Lato-BlackItalic.ttf");
}

@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 100;
    font-display: auto;
    src: url("../../assets/fonts/Lato/Lato-Thin.ttf");
}

@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 300;
    font-display: auto;
    src: url("../../assets/fonts/Lato/Lato-Light.ttf");
}

@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url("../../assets/fonts/Lato/Lato-Regular.ttf");
}

@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-display: auto;
    src: url("../../assets/fonts/Lato/Lato-Bold.ttf");
}

@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 900;
    font-display: auto;
    src: url("../../assets/fonts/Lato/Lato-Black.ttf");
}

@font-face {
    font-family: "Bodoni";
    font-style: normal;
    font-weight: 400;
    src: url("../../assets/fonts/customization-fonts/BodoniModa_18pt-Regular.ttf");
}

@font-face {
    font-family: "Script";
    font-style: normal;
    font-weight: 400;
    src: url("../../assets/fonts/customization-fonts/Script\ MT\ Bold.ttf");
}

@font-face {
    font-family: "English";
    font-style: normal;
    font-weight: 400;
    src: url("../../assets/fonts/customization-fonts/English\ 111\ Presto\ BT.ttf");
}

@font-face {
    font-family: "Swiss";
    font-style: normal;
    font-weight: 400;
    src: url("../../assets/fonts/customization-fonts/Swiss\ 721\ Condensed\ BT.ttf");
}
