
.category {
    margin-bottom: 0.5rem;
    display: flex;
    cursor: pointer;
    align-items: center;
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms
}
.category:hover {
    --tw-gradient-from: #fff var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.category {
    &.active {
        --tw-gradient-from: #fff var(--tw-gradient-from-position);
        --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position);
        --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
    }
    &.active {
        --tw-gradient-to: #FEDF33 var(--tw-gradient-to-position)
    }
}
