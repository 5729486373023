
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.canvas-container {
    /* Tricks to maintain an aspect ratio between width and height */
    height: 0;
    padding-bottom: 60%;
    position: relative;
    grid-column: span 1 / span 1;
    width: 100%;
}
